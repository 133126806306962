<template>
  <div
    :key="componentKey"
    class="carousel"
    :class="{ 'full-screen': fullScreenMode }"
  >
    <base-button
      v-if="fullScreenMode"
      class="carousel__full-screen-close"
      type="secondary"
      @click="toggleFullScreen"
    >
      <base-icon
        name="close"
        class="carousel__button-icon"
      />
    </base-button>
    <base-button
      v-if="showCarouselArrows"
      class="carousel__prev-button"
      :class="{ disabled: disabledSlideState }"
      aria-label="Previous image"
      type="secondary"
      @click="handlePreviousImagePress"
    >
      <base-icon
        name="chevron-left"
        class="carousel__button-icon"
      />
    </base-button>

    <div
      ref="container"
      class="carousel__slide-container"
      :class="{
        custom: $slots.default,
        'ios-transitioning': isTransitioning,
        single: slides.length === 1,
      }"
      @scroll="handleScroll"
    >
      <slot>
        <slide
          v-for="slide in slides"
          :id="slide.id"
          :key="slide.id"
          ref="slidesRef"
          :image="slide"
          :full-screen-mode="fullScreenMode"
          class="carousel__slide"
          @click="handleSlideClick(slide)"
        />
      </slot>
    </div>

    <base-button
      v-if="showCarouselArrows"
      class="carousel__next-button"
      :class="{ disabled: disabledSlideState }"
      aria-label="Next image"
      type="secondary"
      @click="handleNextImagePress"
    >
      <base-icon
        name="chevron-right"
        class="carousel__button-icon"
      />
    </base-button>
  </div>
  <carousel-controls
    v-if="slides"
    class="carousel__controls"
    :image-link="imageLink"
    :images="slides"
    :type="activeControlType"
    :active-slide-index="activeSlideIndex"
    :full-screen-mode="fullScreenMode"
    @handleNext="handleNextImagePress"
    @handlePrevious="handlePreviousImagePress"
    @handleImageSelect="handleImageSelect"
  >
    <slot></slot>
  </carousel-controls>
</template>

<script setup>
import { ref, computed, watch, nextTick, onMounted, onBeforeUnmount } from 'vue';
import { useSlots } from 'vue';
import CarouselControls from './CarouselControlsNew.vue';
import Slide from './SlideNew.vue';
import { BaseButton, BaseIcon } from '@loophq/design-system';
import { useStore } from 'vuex';

import {
  useCarouselNavigation,
  useFullscreenMode,
  useScrollTracking,
} from '@/js/composables/components/carousel/carouselNew.js';

const props = defineProps({
  images: {
    type: Array,
    default: () => null,
  },
  controlType: {
    type: String,
    default: 'thumbnail',
  },
  noImageUrl: {
    type: String,
    default: null,
  },
  selectedImageId: {
    type: [String, Number],
    default: null,
  },
  showFullscreen: {
    type: Boolean,
    default: true,
  },
  interval: {
    type: Number,
    default: 3000,
  },
});

const emit = defineEmits([
  'nextImagePressed',
  'previousImagePressed',
  'imageSelected',
  'fullScreenPressed',
  'collapsePressed',
]);

const store = useStore();
const container = ref(null);
const slidesRef = ref([]);
const slots = useSlots();

const isMobile = computed(() => store.state.screen.width <= 680);

const customSlides = computed(() => {
  if (slots.default) {
    return slots.default().filter((node) => node.type);
  }
  return null;
});

const slides = computed(() => {
  if (customSlides.value) {
    return customSlides.value.map((node, index) => ({
      id: index,
      el: node.el,
    }));
  }
  if (props.images && props.images.length > 0) {
    return props.images;
  }
  return [
    {
      id: 1,
      src: props.noImageUrl,
      alt: 'No image found',
    },
  ];
});

const slideRefs = computed(() => {
  if (customSlides.value) {
    return customSlides.value.map((node) => node.el);
  }
  return slidesRef.value?.map((slide) => slide.$el) ?? [];
});

const {
  activeSlideIndex,
  moveToSlide,
  handleNextImage,
  handlePreviousImage,
  handleImageSelect: selectImage,
} = useCarouselNavigation(slides, emit);

const { fullScreenMode, toggleFullScreen } = useFullscreenMode(isMobile, emit);

const { handleScroll, setupIntersectionObserver } = useScrollTracking(
  container,
  slides,
  activeSlideIndex
);

const imageLink = computed(() => {
  if (slides.value) {
    return `#${slides.value[activeSlideIndex.value]?.id}`;
  }
  return null;
});

const activeControlType = computed(() => {
  if (fullScreenMode.value) {
    return 'thumbnail';
  }
  return props.controlType;
});

const disabledSlideState = computed(() => {
  return !slides.value || slides.value.length <= 1;
});

const showCarouselArrows = computed(() => {
  return activeControlType.value !== 'dot' && slides.value && slides.value.length > 1;
});

const handleNextImagePress = () => {
  handleNextImage(container);
};

const handlePreviousImagePress = () => {
  handlePreviousImage(container);
};

const handleImageSelect = (selectedImage) => {
  selectImage(selectedImage, container);
};

const handleSlideClick = (slide) => {
  if (!fullScreenMode.value) {
    toggleFullScreen();
    selectImage(slide, container);
  }
};

const componentKey = ref(0);
const isTransitioning = ref(false);

// Watch for fullscreen mode changes - this is specifically for ios safari
watch(fullScreenMode, (newVal) => {
  if (newVal) {
    nextTick(() => {
      if (slides.value && slides.value[activeSlideIndex.value]) {
        moveToSlide(slides.value[activeSlideIndex.value].id, container);
      }
    });
  } else {
    isTransitioning.value = true;
    componentKey.value++;

    nextTick(async () => {
      await new Promise((resolve) => setTimeout(resolve, 300));
      isTransitioning.value = false;

      if (slides.value && slides.value[activeSlideIndex.value]) {
        moveToSlide(slides.value[activeSlideIndex.value].id, container);
      }
    });
  }
});

watch(
  () => props.selectedImageId,
  (newVal) => {
    if (newVal) {
      const newIndex = slides.value.findIndex((image) => image.id == newVal);
      if (newIndex > -1) {
        activeSlideIndex.value = newIndex;
      }
      moveToSlide(newVal, container);
    }
  }
);

let observerCleanup = null;

onMounted(() => {
  nextTick(() => {
    if (customSlides.value) {
      customSlides.value.forEach((node, index) => {
        if (node.el) {
          node.el.setAttribute('id', String(index));
        }
      });
    }

    // Setup intersection observer
    observerCleanup = setupIntersectionObserver(slideRefs);

    // Move to selected image if provided
    if (props.selectedImageId) {
      moveToSlide(props.selectedImageId, container);
    }
  });
});

onBeforeUnmount(() => {
  // Clean up observer
  if (observerCleanup) {
    observerCleanup();
  }
});
</script>

<style lang="scss" scoped>
.carousel {
  display: flex;
  justify-content: center;
  height: 90%;
  position: relative;

  &__full-screen-close {
    top: 8px;
    right: 8px;
    color: black;
    z-index: 1000;
    position: absolute;
    height: 40px !important;
    width: 40px !important;
    padding: 12px !important;
    border-radius: 8px;
    border: 2px solid $color-border-primary-default !important;
  }

  &__slide-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    height: 100%;
    width: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    padding: 0.25rem 0;

    &.single {
      justify-content: center;
      overflow-x: hidden;
    }

    &.ios-transitioning {
      transition: none !important;
      transform: none !important;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  + .carousel-controls-new {
    padding-top: 1rem;
  }

  &__slide {
    flex: 0 0 100%;
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }

  &.full-screen {
    position: fixed;
    inset: 0;
    height: 100vh;
    width: 100vw;
    background: white;
    z-index: 10000;
    padding: 0;
    display: flex;
    flex-direction: column;

    .carousel__slide-container {
      flex: 1;
      height: 100%;
      margin: 0;
      scroll-snap-type: x mandatory;
    }

    .carousel__slide {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__prev-button,
  &__next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 40px !important;
    width: 40px !important;
    padding: 12px !important;
    border-radius: 8px;
    border: 2px solid $color-border-primary-default !important;
    z-index: 10002;

    &.disabled {
      background-color: $color-grey-100;
      border: 2px solid $color-border-primary-default;
      cursor: not-allowed;
      color: var(--color-slate-500);
    }

    @media (hover: hover) {
      &:not(.disabled):hover {
        border-color: $text-core-primarySelected !important;
      }
    }
  }

  &__prev-button {
    left: 48px;
  }

  &__next-button {
    right: 48px;
  }

  &__button-icon {
    width: 14px;
    height: 14px;
  }
}

@media screen and (width <= 680px) {
  .carousel {
    height: 100% !important;
    max-height: 336px;

    &__prev-button {
      left: 8px;
    }

    &__next-button {
      right: 8px;
    }

    &__slide {
      max-width: 320px;
      margin: 0.25rem;
    }

    &:not(.full-screen) {
      .carousel__slide-container.single {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .carousel__slide {
          flex: 0 0 320px;
          margin: 0;
        }
      }
    }

    &.full-screen {
      width: 100%;
      padding: 0;
      height: calc(100vh - 80px);
      max-height: none;

      .carousel__slide {
        max-width: none;
        margin: 0;
      }

      .carousel__slide-container {
        padding: 0;
      }

      + .carousel-controls-new {
        position: fixed;
        margin-bottom: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1rem;
        background: white;
        z-index: 10001;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 10%);
      }
    }
  }
}
</style>
