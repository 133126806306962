<template>
  <div class="credit-selector">
    <header class="credit-selector__header">
      <base-text
        class="credit-selector__heading"
        tag="h2"
        type="heading"
      >
        <render-content>
          {{ $content.moduleCreditSelector.heading }}
        </render-content>
      </base-text>
    </header>
    <div
      class="flex flex-column gap-300"
      :class="{
        'flex-column-reverse': isSplitRefund,
      }"
    >
      <radio-input
        v-if="availableOptions.gift"
        v-model="selected"
        class="credit-selector__input"
        :class="{
          selected: selected === returnCreditTypes.GIFT,
        }"
        data-testid="store-credit"
        :value="returnCreditTypes.GIFT"
        @focus="updateFocus"
        @focusout="updateFocus"
        @blur="updateFocus"
      >
        <base-card
          ref="storeCredit"
          class="credit-selector__card"
          tag="span"
          :selected="selected === returnCreditTypes.GIFT"
        >
          <span class="credit-selector__card-content">
            <span class="credit-selector__label">
              <base-text
                class="credit-selector__heading"
                type="heading"
              >
                <render-content>
                  {{ storeCreditHeading }}
                </render-content>
              </base-text>
              <base-text
                v-if="isSplitRefund"
                class="credit-selector__body"
                type="body-2"
              >
                <render-content :data="{ shop: $shop.name }">
                  {{ $content.moduleCreditSelector.splitReturnStoreCreditDescription }}
                </render-content>
              </base-text>
              <base-text
                v-else
                class="credit-selector__body"
                type="body-2"
              >
                <render-content :data="{ shop: $shop.name }">
                  {{ $content.moduleCreditSelector.storeCreditCopyShop }}
                </render-content>
              </base-text>
            </span>
          </span>
        </base-card>
      </radio-input>
      <radio-input
        v-if="availableOptions.refund"
        v-model="selected"
        class="credit-selector__input"
        :class="{
          selected: selected === returnCreditTypes.REFUND,
        }"
        data-testid="original-credit"
        :value="returnCreditTypes.REFUND"
        @focus="updateFocus"
        @focusout="updateFocus"
        @blur="updateFocus"
      >
        <base-card
          ref="originalCredit"
          class="credit-selector__card"
          tag="span"
          :selected="selected === returnCreditTypes.REFUND"
        >
          <span class="credit-selector__card-content">
            <span class="credit-selector__label">
              <base-text
                class="credit-selector__heading"
                type="heading"
              >
                <render-content>
                  {{ originalPaymentHeading }}
                </render-content>
              </base-text>
              <base-text
                v-if="isSplitRefund"
                class="credit-selector__body"
                type="body-2"
              >
                <markup-renderer
                  class="credit-selector__body"
                  :content="$content.moduleCreditSelector.splitReturnRefundDescription"
                />
              </base-text>
              <base-text
                v-else
                class="credit-selector__body"
                type="body-2"
              >
                <render-content>
                  {{ $content.moduleCreditSelector.standardRefundCopy }}
                </render-content>
              </base-text>
            </span>
          </span>
        </base-card>
      </radio-input>
    </div>
  </div>
</template>

<script>
import {
  BaseCard,
  BaseIcon,
  BaseText,
  BaseButton,
  RadioInput,
} from '@loophq/design-system';
import { formatCurrency } from '@/js/helpers/formatCurrency';
import { mapState, mapGetters } from 'vuex';
import CreditSelectorCallout from '@/views/ReviewPage/CreditSelectorCallout';
import firstMile from '@/js/constants/firstMile';
import { returnCreditTypes, storeCreditTypes } from '@/js/constants/returns';
import MarkupRenderer from '@/components/renderers/MarkupRenderer';

export default {
  components: {
    BaseCard,
    BaseButton,
    BaseIcon,
    BaseText,
    CreditSelectorCallout,
    RadioInput,
    MarkupRenderer,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    available: {
      type: Object,
      required: true,
    },
    isSplitRefund: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      refundTotals: null,
      loading: false,
      initialCartLength: 0,
    };
  },
  computed: {
    ...mapState('fees', {
      refundFee: (state) => state.handlingFee?.fees.refund,
      creditFee: (state) => state.handlingFee?.fees.credit,
      exchangeFee: (state) => state.handlingFee?.fees.exchange,
      feesLoading: (state) => state.loading,
    }),
    ...mapState('totals', {
      bonusCredit: (state) => state.totals.credit.bonus.storeCreditBonus,
      exchangeRatio: (state) => state.totals.computed.exchangeRatio,
    }),
    ...mapGetters({
      feesLoading: 'fees/loading',
      shop: 'shop',
    }),
    storeCreditHeading() {
      return this.$content.moduleCreditSelector.storeCreditHeading;
    },
    originalPaymentHeading() {
      return this.$content.moduleCreditSelector.standardRefundHeading;
    },
    returnCreditTypes() {
      return returnCreditTypes;
    },
    storeCreditTypes() {
      return storeCreditTypes;
    },
    giftCardIncentiveType() {
      return this.order.return_policy.gift_card_incentive_type;
    },
    hasExchangeFee() {
      return this.exchangeFee?.amount > 0;
    },
    hasRefundFee() {
      // Use exchange fee if ratio is greater than .5
      if (this.exchangeRatio >= 0.5) {
        return this.hasExchangeFee;
      }

      return this.refundFee?.amount > 0;
    },
    hasStoreCreditFee() {
      // Use exchange fee if ratio is greater than .5
      if (this.exchangeRatio >= 0.5) {
        return this.hasExchangeFee;
      }

      return this.creditFee?.amount > 0;
    },
    hasStoreCreditBonus() {
      return this.bonusCredit > 0;
    },
    displayShopName() {
      return this.$store.getters.shop.name;
    },
    displayCurrency() {
      return this.$store.getters['currencies/displayCurrency'];
    },
    totals() {
      return this.$store.state.totals.totals;
    },
    selected: {
      get() {
        if (this.order.creditType == returnCreditTypes.GIFT) {
          return returnCreditTypes.GIFT;
        } else if (this.order.creditType == returnCreditTypes.REFUND) {
          return returnCreditTypes.REFUND;
        } else {
          return '';
        }
      },
      set(val) {
        let creditType;
        let storeCreditType;
        switch (val) {
          case returnCreditTypes.GIFT:
            creditType = val;
            storeCreditType = storeCreditTypes.GIFTCARD;
            break;
          case returnCreditTypes.REFUND:
            creditType = val;
            // this isn't relevant to refunds, but we use "GIFTCARD" as our default for storeCreditType
            storeCreditType = storeCreditTypes.GIFTCARD;
            break;
          default:
            break;
        }

        this.$emit('input', { creditType, storeCreditType });
      },
    },
    availableOptions() {
      const options = { ...this.available };
      if (this.$store.getters.wasCashPurchased) {
        if ('refund' in options) {
          options.refund = false;
        }
      }
      return options;
    },
    customizedHandlingFeeDisclaimer() {
      const hasCustomizations = this.$content.moduleCreditSelector.handlingFeeDisclaimer;
      if (hasCustomizations) {
        return {
          handlingFee: this.formatAmount(this.refundFee?.amount),
        };
      }
      // hide disclaimer if no customizations for the message
      return null;
    },
    isLoopPosReturnMethod() {
      return this.$store.getters.order?.returnMethod?.name === firstMile.LOOP_POS;
    },
  },
  methods: {
    formatAmount(amount) {
      return formatCurrency(amount, this.$settings.currency);
    },
    updateFocus(el) {
      switch (el.target.value) {
        case returnCreditTypes.GIFT:
          this.$refs.storeCredit.$el.classList.toggle('focused');
          break;
        case returnCreditTypes.REFUND:
          this.$refs.originalCredit.$el.classList.toggle('focused');
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$block: '.credit-selector';

#{$block} {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-300);

  &__header {
    display: flex;
  }

  &__heading {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-100);
  }

  &__input {
    display: flex;
    align-items: center;
    gap: var(--spacing-300);
    padding: var(--spacing-300) var(--spacing-300);
    border: 1px solid $color-border-primary-default;
    border-radius: 12px;

    &:hover:not(.selected),
    &:focus:not(.selected) {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 12%), 0 6px 12px rgba(0, 0, 0, 8%);
    }

    &.selected {
      border: 1px solid var(--grey-900);
      box-shadow: 0 0 0 1px var(--grey-900);

      #{$block}__heading,
      #{$block}__card-icon {
        color: var(--heading-color);
      }
    }
  }

  &__card {
    padding: 0;
    box-shadow: none;
    transition: box-shadow var(--transition-300);
    width: calc(100% - 2.5rem);
    margin-left: 2.5rem;

    &:not(.selected),
    &.selected {
      box-shadow: none;
      border: none;
    }

    #{$block}__heading,
    #{$block}__card-icon {
      flex-shrink: 0;
      color: var(--heading-color);
    }
  }

  &__card-content {
    display: flex;
    gap: var(--spacing-300);
  }

  // hides the custom radio button UI element from DOM to allow card styling
  // while maintaining radio input functionality.
  &__input {
    &:deep(.radio-input__label) {
      &::before {
        position: absolute;
        top: calc(50% - 0.75rem);

        &:checked {
          box-shadow: inset 0 0 0 5px black, 0 0 0 4px transparent !important;
        }
      }

      &::after {
        position: absolute;
        top: calc(50% - 0.75rem);
      }
    }
  }

  &__body {
    margin-top: var(--spacing-100);
    max-width: 100%;
    color: var(--heading-color);

    :deep(p) {
      color: inherit !important;
      font-size: 0.875rem !important;

      em {
        color: var(--color-grey-700) !important;
      }
    }
  }

  &__learn-more {
    font-size: 0.9rem !important;
    margin-left: var(--spacing-100);
  }

  :deep(.radio-input__input:checked + .radio-input__label) {
    --secondary-color: black;
  }
}
</style>
