<template>
  <div class="bundle-stack">
    <div class="bundle-stack__card"></div>
    <div class="bundle-stack__card offset"></div>
    <div class="bundle-stack__card offset-product">
      <product-card-image :product="product" />
    </div>
  </div>
</template>

<script>
import ProductCardImage from '@/components/product/ProductCardImage';

export default {
  components: {
    ProductCardImage,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.bundle-stack {
  position: relative;
  margin-right: 1.5rem;

  &__card {
    height: 80px;
    width: 80px;
    background: #f7f8f9;
    border: 1px solid #eaeaea;
    border-radius: 4px;

    &.offset {
      position: absolute;
      top: -5px;
      left: 5px;
    }

    &.offset-product {
      position: absolute;
      left: 10px;
      top: -10px;

      img {
        height: 78px;
        width: 78px;
        object-fit: cover;
        margin: 0;
      }
    }
  }
}
</style>
