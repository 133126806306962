<template>
  <div class="carousel-controls__dot-container">
    <div
      v-for="(image, index) in images"
      :key="image.id"
      class="carousel-controls__marker"
      :class="{ active: activeSlideIndex == index }"
      @click="handleImageSelect(image, index)"
    ></div>
  </div>
</template>

<script setup>
const emit = defineEmits(['handleImageSelect']);

defineProps({
  images: {
    type: Array,
    default: () => [],
  },
  activeSlideIndex: {
    type: Number,
    default: 0,
  },
});

const handleImageSelect = (image, index) => {
  emit('handleImageSelect', { image, index });
};
</script>

<style lang="scss" scoped>
.carousel-controls {
  &__dot-container {
    display: flex;
  }

  &__marker {
    background: $color-grey-fill;
    display: block;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin: var(--spacing-100) 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 10%);

    &.active {
      background: $gray-darker;
      opacity: 1;
    }
  }
}
</style>
