<template>
  <label
    class="product-swatch__label"
    data-testid="product-swatch"
    :class="[{ disabled: item.disabled }, { active: isActive }]"
    :for="'option-' + item.name"
  >
    <input
      :id="'option-' + item.name"
      v-model="input"
      class="product-swatch__input"
      type="radio"
      :value="item.name"
      :disabled="item.disabled"
    />
    <transition name="fadein">
      <img
        v-if="item.image"
        class="product-swatch__image"
        :class="[{ disabled: item.disabled }, { active: isActive }]"
        :src="item.image"
        alt=""
        data-testid="product-swatch-image"
        @click.prevent="selectItem"
      />
      <span
        v-else
        class="product-swatch__text"
        :class="{ hidden: item.image }"
      />
    </transition>
  </label>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);
const input = ref('');

const isActive = computed(() => {
  return props.modelValue === props.item.name || props.active === props.item.name;
});

const selectItem = () => {
  if (!props.item.disabled) {
    input.value = props.item.name;
  }
};

watch(input, (newValue) => {
  emit('update:modelValue', newValue);
});

onMounted(() => {
  if (props.modelValue) {
    input.value = props.modelValue;
  }
});
</script>

<style lang="scss" scoped>
.product-swatch {
  border-radius: 8px;

  .fadein-enter-active {
    transition: opacity 0.24s;
  }

  .fadein-enter,
  .fadein-leave-to {
    opacity: 0;
  }

  &__label {
    position: relative;
    min-width: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.9375rem;
    font-weight: 400;

    &.disabled {
      cursor: not-allowed;
      background-color: lightgray;
      border-color: $color-border-primary-default;
      border-radius: 8px;

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        background:
          linear-gradient(
            to top right,
            transparent calc(50% - 2px),
            $color-border-primary-default,
            transparent calc(50% + 2px)
          );
        pointer-events: none;
      }
    }

    &.active {
      border-color: $text-core-primarySelected;
    }
  }

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  &__text {
    @include skeleton;

    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--grey-200);

    &.hidden {
      position: absolute;
      width: 40px;
      height: 40px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }
  }

  &__image {
    cursor: pointer;
    min-width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: var(--theme-corners);
    border: 2px solid $color-border-primary-default;
    background-color: white;
    transition: border-color 0.2s ease;

    &:hover:not(.disabled) {
      border-color: $text-core-primarySelected;
    }

    &.active {
      border-color: $text-core-primarySelected;
    }

    &.disabled {
      opacity: 0.5;
      background-color: lightgray;
      border-color: $color-border-primary-default;
      cursor: not-allowed;
    }
  }
}
</style>
