<template>
  <div>
    <div
      v-if="loading"
      class="product-images__loading-wrap"
    >
      <img
        class="product-images__loading-image"
        src="/img/icons/no-image[cropped].svg"
        alt="Product image loading indicator"
      />
    </div>
    <component
      :is="carouselComponent"
      v-else
      :images="images"
      no-image-url="
      img
      icons
      no-image2.svg"
      :selected-image-id="productImage && productImage.id"
      :control-type="controlType"
      @imageSelected="trackEvent('imageSelected')"
      @previousImagePressed="trackEvent('previous')"
      @nextImagePressed="trackEvent('next')"
      @fullScreenPressed="$emit('fullScreenPressed')"
      @collapsePressed="$emit('collapsePressed')"
    />
  </div>
</template>

<script>
import { Carousel } from '@loophq/design-system';
import CarouselNew from '../carousel/CarouselNew.vue';
import { featureFlags } from '@/js/constants/featureFlags';
import { experiments } from '@/js/constants/experiments';

export default {
  components: {
    Carousel,
    CarouselNew,
  },
  props: {
    images: {
      type: Array,
      default: null,
    },
    selectedImage: {
      type: String,
      default: null,
    },
    controlType: {
      type: String,
      default: 'thumbnail',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['trackEvent', 'fullScreenPressed', 'collapsePressed'],
  computed: {
    productImage() {
      return this.images?.find((image) => image.src === this.selectedImage);
    },
    carouselComponent() {
      return this.$store.getters.experimentVariation(
        featureFlags.PRODUCT_DETAIL_PAGE_IMPROVEMENTS
      ) === experiments.NEW_PDP
        ? CarouselNew
        : Carousel;
    },
  },
  methods: {
    trackEvent(event) {
      this.$emit('trackEvent', event);
    },
  },
};
</script>

<style lang="scss">
.product-images__loading-wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include skeleton;
}

.product-images__loading-image {
  max-height: 6rem;
  width: auto;
}

.product-modal-product__carousel {
  .slide img {
    border-radius: var(--corners);
  }
}

.carousel {
  &.full-screen {
    z-index: 5001;
    background: white;
  }
}

.slide-module__img {
  .collapse-screen-btn {
    top: 70px !important;
  }

  .full-screen {
    .carousel-controls {
      bottom: 100px !important;
    }
  }
}
</style>
