<template>
  <div class="carousel-controls-new__container">
    <div
      v-if="$slots.default()[0].children?.length > 0"
      class="carousel-controls-new__thumbnails"
    >
      <slot></slot>
    </div>
    <base-button
      v-for="(image, index) in thumbnails"
      v-else
      :key="'thumbnail-' + image.id"
      class="carousel-controls-new__thumbnail"
      :class="{ active: imageLink === '#' + image.id }"
      type="secondary"
      @click="selectImage(image, index)"
    >
      <img
        :src="image.src"
        :alt="image.alt"
      />
    </base-button>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { BaseButton } from '@loophq/design-system';

const props = defineProps({
  imageLink: {
    type: String,
    default: null,
  },
  images: {
    type: Array,
    default: () => [],
  },
  activeSlideIndex: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['handleNext', 'handlePrevious', 'handleImageSelect']);

const thumbnails = computed(() => {
  if (props.images.length > 8) {
    if (props.activeSlideIndex > 5) {
      const startingIndex = props.activeSlideIndex - 6;
      const endingIndex = props.activeSlideIndex + 1;
      return props.images.slice(startingIndex, endingIndex + 1);
    } else {
      return props.images.slice(0, 8);
    }
  }
  return props.images;
});

const selectImage = (image, index) => {
  if (index < 7) {
    emit('handleImageSelect', image);
  }
};
</script>

<style lang="scss">
.carousel-controls-new {
  &__container {
    display: flex;
  }

  &__thumbnails {
    display: flex;
    border-radius: 8px;
    border: 2px solid $color-border-primary-default;

    > * {
      height: 48px;
      width: 48px;
      margin: 0 0.35rem !important;
    }
  }

  &__thumbnail {
    height: 48px;
    width: 48px;
    padding: 0 !important;
    margin: 0 0.35rem !important;
    border-radius: 8px !important;
    border: 2px solid $color-border-primary-default !important;
    overflow: hidden !important;
    position: relative !important;
    transition: border-color 0.3s ease !important;

    &:first-child {
      margin-left: 0 !important;
    }

    .base-button__text {
      height: 100%;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
    }

    &.active {
      border-color: black !important;
    }
  }
}
</style>
