<template>
  <card
    type="default"
    class="return-coverage-card"
  >
    <div class="return-coverage-card__content">
      <div class="return-coverage-card__content__image-container">
        <div class="icon">
          <img
            src="../../../assets/return-coverage-icon.svg"
            alt="{{ $content.moduleReturnCoverage.card.header }}"
          />
        </div>
      </div>
      <div>
        <base-text
          class="return-coverage-card__content__header"
          type="heading"
        >
          {{ $content.moduleReturnCoverage.card.header }}
        </base-text>

        <base-text
          class="return-coverage-card__content__text"
          type="body"
          tabindex="0"
          @click="showPanel"
        >
          {{ $content.moduleReturnCoverage.card.message }}
        </base-text>
      </div>
    </div>
  </card>

  <base-panel
    class="return-coverage-card__panel"
    type="panel"
    :active="panelActive"
    @close="hidePanel"
  >
    <template #footer>
      <base-button
        class="panel-go-back-button"
        type="primary"
        @click="hidePanel"
      >
        {{ $content.moduleReturnCoverage.modal.gotIt }}
      </base-button>
    </template>
    <div class="return-coverage-card__panel__header">
      <img
        src="../../../assets/return-coverage-icon.svg"
        alt="{{ $content.moduleReturnCoverage.card.header }}"
        class="return-coverage-card__panel__icon"
      />
      {{ $content.moduleReturnCoverage.modal.header }}
    </div>
    <div>
      <base-text
        class="return-coverage-card__panel__text"
        type="body"
      >
        {{ $content.moduleReturnCoverage.modal.message1 }}
      </base-text>
      <base-text
        class="return-coverage-card__panel__text italic"
        type="body"
      >
        {{ $content.moduleReturnCoverage.modal.message2 }}
      </base-text>
    </div>
  </base-panel>
</template>

<script setup>
import { ref } from 'vue';
import Card from '@/components/layout/Card.vue';
import { BaseButton, BasePanel, BaseText } from '@loophq/design-system';

const panelActive = ref(false);
function showPanel() {
  panelActive.value = true;
}
function hidePanel() {
  panelActive.value = false;
}
</script>

<style lang="scss" scoped>
.icon {
  width: 32px;
}

.return-coverage-card {
  border-radius: var(--radius-banner, 12px);
  background: var(--color-lochmara-100, #e5eef9) !important;
  margin-bottom: 1rem;

  &__content {
    display: flex;
    align-items: center;
    margin: -1.25rem;

    &__image-container {
      display: flex;
      flex: none;
      width: 100px;
      height: 88px;
      margin-right: 0.5rem;
      align-items: center;
      justify-content: center;
    }

    &__header {
      font-size: 16px;
    }

    &__text {
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__panel {
    &__icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    &__header {
      font-size: 1.5rem;
      display: flex;
      column-gap: 6px;
      margin-bottom: 1rem;
      align-items: center;
    }

    &__text {
      font-size: 14px;
      margin-bottom: 1rem;

      &.italic {
        font-style: italic;
      }
    }

    .panel-go-back-button {
      width: 100%;
      background-color: #000;
    }
  }
}
</style>
