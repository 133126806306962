<template>
  <div
    :class="
      showProductDescriptionCheck
        ? 'product-modal__container'
        : 'product-modal__container product-modal__big-image'
    "
    @keydown.esc="close"
  >
    <transition
      appear
      name="backdrop"
      :duration="{ enter: 0, leave: 3000 }"
    >
      <div
        v-if="status && !$slots.overlay"
        class="product-modal__background"
        @click="close"
      ></div>
    </transition>

    <div
      v-if="$slots.overlay"
      class="product-modal overlay"
    >
      <slot name="overlay"></slot>
    </div>
    <transition
      appear
      name="modal"
      mode="out-in"
      :duration="{ enter: 0, leave: 1500 }"
    >
      <div
        v-if="status"
        class="product-modal card"
        :class="{ hidden: !!$slots.overlay }"
      >
        <div class="product-modal__body">
          <base-button
            v-if="!flowBFinalStep && !fullScreenActive"
            class="product-modal__close"
            type="icon"
            icon="close"
            data-testid="close-product-modal"
            @click="close"
          >
            <visually-hidden>
              {{ $content.global.close }}
            </visually-hidden>
          </base-button>

          <div class="product-modal__product">
            <product-images
              v-if="!isMobile && showMultipleImages"
              class="product-modal__product-carousel"
              :images="images"
              :loading="loading"
              :selected-image="image"
              @trackEvent="trackEvent"
            />
            <div
              v-if="!showMultipleImages"
              class="product-modal__product-image-container"
            >
              <div
                v-if="exchangeOnly && showProductDescriptionCheck"
                class="product-modal__product-status-badge"
              >
                <span>{{ $content.moduleSingleReturnOutcome.exchangeOnly }}</span>
              </div>
              <img
                v-if="image === null"
                class="product-modal__product-image"
                src="/img/icons/no-image2.svg"
                alt=""
              />
              <lazy-img
                v-else
                :key="image"
                class="product-modal__product-image"
                alt=""
                :src="productImage"
                @error="imageBackup($event, 'no-image2.svg')"
              />
            </div>
            <div
              v-if="candidate && showProductDescriptionCheck"
              class="product-modal__product-description"
            >
              <p class="product-modal__product-title">
                {{ candidate.title }}
              </p>
              <p v-if="candidate.variant_title && candidate.variant_title.length > 0">
                {{ candidate.variant_title }}
              </p>
              <product-price
                :product="candidate"
                :module="module"
              />
              <store-credit-only-badge
                v-if="splitRefundsEnabled"
                :product="candidate"
              />
            </div>
          </div>

          <div class="product-modal__slides">
            <div class="product-modal__slides-container">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
const { imageBackup } = useUtilMethods();
</script>

<script>
import useUtilMethods from '@/js/mixins/util.js';
const { miniImage } = useUtilMethods();
import { BaseButton, VisuallyHidden } from '@loophq/design-system';
import LazyImg from '@/components/LazyImg.vue';
import ProductImages from '@/components/product/ProductImages';
import ProductPrice from '@/components/product/ProductPrice';
import StoreCreditOnlyBadge from '@/components/badges/StoreCreditOnlyBadge';
import { featureFlags } from '@/js/constants/featureFlags';
import { experiments } from '@/js/constants/experiments';

export default {
  components: {
    LazyImg,
    BaseButton,
    VisuallyHidden,
    ProductImages,
    ProductPrice,
    StoreCreditOnlyBadge,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },

    candidate: {
      type: Object,
      required: true,
    },

    status: {
      type: Boolean,
      required: true,
    },

    image: {
      type: String,
    },

    images: {
      type: Array,
      default: null,
    },

    module: {
      type: String,
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    showProductDescription: {
      type: Boolean,
      default: true,
    },

    flowBFinalStep: {
      type: Boolean,
    },

    fullScreenActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'changeImage'],
  computed: {
    isMobile() {
      return this.$screen.width <= 680;
    },
    showMultipleImages() {
      return this.module === 'SelectVariant' || window.location.hash.includes('shop');
    },
    productImage() {
      return miniImage(this.image, 1200);
    },
    allowExchange: function () {
      const canExchange =
        this.candidate.variants.length !== 1 && this.candidate.allowed.exchange;
      // if order allowlisted, we don't check workflow exclusions.
      return this.order.allowlisted
        ? canExchange
        : canExchange && !this.candidate.excluded.inlineExchange;
    },
    allowReturn() {
      const canReturn = this.candidate.allowed.return;
      // if order allowlisted, we don't check workflow exclusions.
      return this.order.allowlisted
        ? canReturn
        : canReturn &&
          !(this.candidate.excluded.refund && this.candidate.excluded.storeCredit);
    },
    exchangeOnly: function () {
      return this.allowExchange && !this.allowReturn;
    },
    splitRefundsEnabled() {
      return this.$store.getters.settings.enableSplitRefunds;
    },
    productDescriptionPageUpdatesEnabled() {
      return (
        this.module === 'SelectVariant' &&
        this.$store.getters.experimentVariation(
          featureFlags.PRODUCT_DETAIL_PAGE_IMPROVEMENTS
        ) === experiments.NEW_PDP
      );
    },
    showProductDescriptionCheck() {
      return this.showProductDescription && !this.productDescriptionPageUpdatesEnabled;
    },
  },

  created: function () {
    //Watch for close key events
    window.addEventListener('keydown', this.keyWatch);
    this.$emit('changeImage', this.candidate.image, this.candidate.images);
  },
  beforeUnmount: function () {
    window.removeEventListener('keydown', this.keyWatch);
  },
  methods: {
    close: function () {
      if (!this.flowBFinalStep) {
        this.$emit('close');
      }
    },
    keyWatch: function (event) {
      if (event.key === 'Escape' && !this.flowBFinalStep) {
        this.close();
      }
    },
    trackEvent: function (type) {
      const label = window.location.hash.includes('shop') ? 'ShopNow' : 'Exchange';
      this.$trackEvent(`${label} carousel ${type}`);
    },
  },
  render: (h) => h(this),
};
</script>

<style lang="scss" scoped>
.product-modal {
  &__close {
    position: absolute;
    top: var(--spacing-300);
    right: var(--spacing-300);
    z-index: 5000;
    color: var(--grey-900);
  }
}

.overlay {
  position: absolute;
  z-index: 10000;
  background: transparent;
  box-shadow: none;
  overflow: none;
}

.hidden {
  opacity: 0;
  transition: opacity 0.01s linear 0.5s;
}
</style>
