<template>
  <div
    class="product-option"
    test-id="product-option-new"
  >
    <header class="product-option__header">
      <base-text
        tag="h3"
        class="product-option__heading"
      >
        {{
          imageSlugs.includes(option.name.toLowerCase()) ? `${option.name}:` : option.name
        }}
      </base-text>
      <base-text
        v-if="active && imageSlugs.includes(option.name.toLowerCase())"
        tag="p"
        class="product-option__value"
      >
        {{ active }}
      </base-text>
    </header>
    <section
      v-if="imageSlugs.includes(option.name.toLowerCase())"
      class="product-option__main"
    >
      <div class="product-option__radio-main">
        <product-swatch
          v-for="item in mappedItems"
          :key="item.id"
          v-model="input"
          :item="item"
          class="product-option__options"
        />
        <a
          v-if="hasLink"
          :href="sizeChart.url"
          class="product-option__link"
          target="_blank"
          rel="noopener"
        >
          {{ sizeChart.copy }}
        </a>
      </div>
    </section>
    <section
      v-else
      class="product-option__main"
    >
      <div class="product-option__radio-main">
        <product-radio
          v-for="item in mappedItems"
          :key="item.id"
          v-model="input"
          :item="item"
          :active="active"
          class="product-option__options"
        />
      </div>
      <a
        v-if="hasLink"
        :href="sizeChart.url"
        class="product-option__link"
        target="_blank"
        rel="noopener"
      >
        {{ sizeChart.copy }}
      </a>
    </section>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import ProductRadio from './ProductOptionRadioNew.vue';
import ProductSwatch from './ProductOptionSwatchNew.vue';
import { BaseText } from '@loophq/design-system';
import { getOutOfStock, getUnavailable } from '@/js/helpers/outOfStock';
import { slugs } from '@/js/constants/imageSlugs';
import { useStore } from 'vuex';
import useUtilMethods from '@/js/mixins/util';
const { miniImage } = useUtilMethods();

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  variants: {
    type: [Array, Object],
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  selected: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const emit = defineEmits(['input', 'scroll']);

const input = ref('');
const imageSlugs = ref(slugs);
const store = useStore();
const content = store.getters['content'];

const hasLink = computed(() => {
  return (
    sizeChart.value &&
    sizeChart.value.url &&
    sizeChart.value.copy &&
    sizeChart.value.option &&
    props.option.name.toLowerCase() === sizeChart.value.option.toLowerCase()
  );
});

const sizeChart = computed(() => {
  const { sizeChartCopy, sizeChartURL, sizeChartOption } = content.moduleProductExchange;

  return {
    url: sizeChartURL,
    copy: sizeChartCopy,
    option: sizeChartOption,
  };
});

const active = computed(() => {
  const findActive = props.selected.find((option) => option.name === props.option.name);
  if (findActive) {
    return findActive.value;
  }
  return null;
});

const filteredOptions = computed(() => {
  if (Array.isArray(props.variants)) {
    // We want to ignore the current option when looking up out of stock/unavailable
    // And just calculate out of stock as if the current one isn't selected
    return props.selected
      .filter((item) => item.name !== props.option.name)
      .reduce((accumulator, current) => {
        return {
          ...accumulator,
          [current.option]: current.value,
        };
      }, {});
  }

  return [];
});

const outOfStock = computed(() => {
  if (Array.isArray(props.variants)) {
    return getOutOfStock(
      props.variants,
      filteredOptions.value,
      props.option.position,
      (variant) => {
        if (!store.state.shopContents.use_shopify_inventory) {
          return false;
        }

        return variant.limit - getExistingExchanges(variant) <= 0;
      }
    );
  }

  return [];
});

const unavailable = computed(() => {
  if (Array.isArray(props.variants)) {
    return getUnavailable(props.variants, filteredOptions.value);
  }

  return [];
});

const mappedItems = computed(() => {
  return props.option.values.map((item) => {
    // We need a unique number for making ids that don't clash
    const random = [...Array(16)]
      .map(() => (~~(Math.random() * 36)).toString(36))
      .join('');
    const variant =
      Array.isArray(unavailable.value) &&
      props.variants.find(
        (variant) =>
          variant.option1 === item || variant.option2 === item || variant.option3 === item
      );
    const disabled = outOfStock.value.includes(item);
    const isUnavailable = unavailable.value.includes(item);
    const message = isUnavailable
      ? content.moduleSelectVariant.unavailable
      : content.global.noStock;
    return {
      name: item,
      image: variant && variant.image ? miniImage(variant.image) : null,
      disabled,
      message: disabled ? message : null,
      id: `option-${random}`,
    };
  });
});

const getExistingExchanges = (variant) => {
  return Object.values(store.getters.order.line_items).reduce((accumulator, current) => {
    if (current.new_variant && current.new_variant.id === variant.id) {
      return accumulator + 1;
    }
    return accumulator;
  }, 0);
};

watch(input, (value) => {
  emit('input', {
    name: props.option.name,
    value,
    option: `option${props.option.position}`,
  });
});

if (active.value) {
  input.value = active.value;
}
</script>

<style lang="scss" scoped>
.product-option {
  width: 100%;
  background-color: white;

  &__header {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 1.5rem 0 0.75rem 2.5rem;
    text-transform: capitalize;
  }

  &__heading {
    font-size: 0.9rem;
    font-weight: 600;
    color: $gray-darker;
  }

  &__link {
    margin-right: auto;
    font-size: 0.875rem;
    text-transform: none;
    margin-top: 0.75rem;

    &:hover {
      color: var(--grey-900);
      transition: color 0.24s ease;
    }
  }

  &__value {
    font-size: 0.9rem;
    text-align: right;
    color: $gray-darker;
  }

  &__main {
    display: flex;
    flex-direction: column;
    padding: 0 1.25rem 0 2.5rem;
    overflow: auto;
  }

  &__radio-main {
    flex-flow: row wrap;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    gap: 0.5rem;
  }
}

@media screen and (width <= 680px) {
  .product-option {
    &__header {
      padding: 1.5rem 1.25rem 1rem;
    }

    &__main {
      padding: 0 1.25rem;
    }
  }
}
</style>
