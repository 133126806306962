import { ref } from 'vue';

export function useCarouselNavigation(slides, emit) {
  const activeSlideIndex = ref(0);
  const slidesValue = slides.value.length - 1;

  const moveToSlide = (id, container) => {
    if (container.value) {
      const leftOffset = document.getElementById(id)?.offsetLeft;
      container.value.scrollTo(leftOffset, 0);
    }
  };

  const handleNextImage = (container) => {
    if (activeSlideIndex.value === slidesValue) {
      activeSlideIndex.value = 0;
    } else {
      activeSlideIndex.value += 1;
    }

    emit('nextImagePressed');
    moveToSlide(slides.value[activeSlideIndex.value].id, container);
  };

  const handlePreviousImage = (container) => {
    if (activeSlideIndex.value === 0) {
      activeSlideIndex.value = slidesValue;
    } else {
      activeSlideIndex.value -= 1;
    }

    emit('previousImagePressed');
    moveToSlide(slides.value[activeSlideIndex.value].id, container);
  };

  const handleImageSelect = (selectedImage, container) => {
    emit('imageSelected', selectedImage);
    const index = slides.value.findIndex((image) => image.id === selectedImage.id);
    activeSlideIndex.value = index;
    moveToSlide(slides.value[activeSlideIndex.value].id, container);
  };

  return {
    activeSlideIndex,
    moveToSlide,
    handleNextImage,
    handlePreviousImage,
    handleImageSelect
  };
}

export function useFullscreenMode(isMobile, emit) {
  const fullScreenMode = ref(false);

  const handleFullScreenPress = () => {
    if (!isMobile.value) return;

    fullScreenMode.value = true;
    emit('fullScreenPressed');
  };

  const handleCollapsePress = () => {
    if (!isMobile.value) return;

    fullScreenMode.value = false;
    emit('collapsePressed');
  };

  const toggleFullScreen = () => {
    if (fullScreenMode.value) {
      handleCollapsePress();
    } else {
      handleFullScreenPress();
    }
  };

  return {
    fullScreenMode,
    handleFullScreenPress,
    handleCollapsePress,
    toggleFullScreen
  };
}

export function useScrollTracking(container, slides, activeSlideIndex) {
  const handleScroll = () => {
    if (!container.value) return;

    const scrollLeft = container.value.scrollLeft;
    const containerWidth = container.value.offsetWidth;
    const scrollWidth = container.value.scrollWidth;

    const viewportCenter = scrollLeft + (containerWidth / 2);

    const slideWidth = scrollWidth / slides.value.length;

    const currentIndex = Math.min(
      Math.floor(viewportCenter / slideWidth),
      slides.value.length - 1
    );

    if (currentIndex >= 0 &&
        currentIndex < slides.value.length &&
        currentIndex !== activeSlideIndex.value) {
      activeSlideIndex.value = currentIndex;
    }
  };

  const setupIntersectionObserver = () => {
    if (!container.value) return;

    container.value.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      container.value?.removeEventListener('scroll', handleScroll);
    };
  };

  return {
    handleScroll,
    setupIntersectionObserver
  };
}
