<template>
  <base-card class="tracking-product-info__tracking-card">
    <base-text
      class="tracking-product-info__messaging-heading tracking-product-info__messaging-heading-internal"
      type="display-medium"
      data-testid="tracking-product-info_heading_internal"
    >
      {{
        currentShippingStatus === labelStatuses.DELIVERED
          ? $content.moduleTrackingProductInformation.receivedSubHeading
          : $content.moduleTrackingProductInformation.unreceivedSubHeading
      }}
    </base-text>
    <div class="tracking-product-info__products">
      <div class="tracking-product-info__products-list">
        <div
          v-for="item in lineItems"
          :key="item.productId"
          class="tracking-product-info__product-card"
          data-testid="tracking_product_info_product_card"
          @click="navigateToProduct(item.link)"
        >
          <div class="tracking-product-info__image">
            <img
              class="tracking-product-info__img"
              :src="item.image ?? '/img/icons/no-image.svg'"
              :alt="item.title + ' ' + item.variantTitle"
            />
          </div>
          <div class="tracking-product-info__product-details">
            <base-text
              class="tracking-product-info__product-title"
              type="body"
            >
              {{ item.title }}
            </base-text>
            <base-text
              class="tracking-product-info__product-variant-title"
              type="body"
            >
              {{ item.variantTitle }}
            </base-text>
          </div>
        </div>
      </div>

      <div
        v-if="portalReturnUrl"
        class="tracking-product-info__start-a-return-section"
        data-testid="tracking_product_info_start_a_return_section"
      >
        <div class="tracking-product-info__cta">
          <base-text
            class="tracking-product-info__messaging-heading"
            type="body-2"
            data-testid="tracking-product-info_heading"
          >
            {{ $content.moduleTrackingProductInformation.startAReturnText }}
          </base-text>

          <base-button
            type="primary"
            size="small"
            class="tracking-product-info__start-a-return"
            data-testid="tracking_product_info_start_a_return"
            target="_blank"
            :to="portalReturnUrl"
            @click="customerStartedAReturn"
          >
            {{ $content.moduleTrackingProductInformation.startAReturn }}
          </base-button>
        </div>
      </div>
    </div>
  </base-card>
</template>

<script setup>
import { BaseText, BaseButton } from '@loophq/design-system';
import { labelStatuses } from '@/js/constants/labels';

defineProps({
  currentShippingStatus: {
    type: String,
    required: true,
  },
  orderName: {
    type: String,
    required: true,
  },
  lineItems: {
    type: Array,
    required: true,
  },
  portalReturnUrl: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(['customerStartedReturn']);

const navigateToProduct = (link) => {
  if (!link) return;
  window.open(link, '_blank');
};

const customerStartedAReturn = () => {
  emit('customerStartedReturn');
};
</script>

<style lang="scss" scoped>
$block: '.tracking-product-info';

#{$block} {
  &__tracking-card {
    display: flex;
    justify-content: space-around;
    background: white;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: 980px;
    border-radius: var(--corners);
    border: 1px solid $color-border-primary-default;
    margin: var(--spacing-400) auto;
    padding: 2.5rem;
    column-gap: var(--spacing-300);
  }

  &__tracking-card-no-border {
    display: flex;
    background: white;
    flex-wrap: wrap;
    align-items: stretch;
    margin: auto;
    width: 100%;
    max-width: 980px;
    padding-top: var(--spacing-400);
  }

  &__messaging-heading {
    width: 100%;
    text-align: left;
  }

  &__products {
    width: 100%;
    display: flex;
    column-gap: var(--spacing-300);
  }

  &__products-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 190px);
    justify-content: space-between;
  }

  &__product-card {
    margin: var(--spacing-200) auto;
    width: 180px;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    img {
      cursor: pointer;
      height: 180px;
      width: 180px;
      margin: var(--spacing-100);
      object-fit: contain;
    }
  }

  &__product-title {
    color: #000;
    font-weight: 600;
  }

  &__start-a-return-section {
    min-width: 212px;
    display: flex;
    justify-content: center;
    padding: 0 var(--spacing-300);
    border-left: solid 1px $color-border-primary-default;
  }

  &__cta {
    margin-top: 3.5rem;
  }

  &__messaging-heading {
    margin-bottom: var(--spacing-200);
  }
}

@media screen and (width <= 680px) {
  #{$block} {
    &__tracking-card {
      padding: var(--spacing-200);
    }

    &__tracking-card-no-border {
      padding: var(--spacing-300);
    }

    &__messaging-heading {
      text-align: center;
    }

    &__messaging-heading-internal {
      padding-top: var(--spacing-300);
    }

    &__products {
      display: initial;
    }

    &__products-list {
      display: flex;
      flex-wrap: wrap;
    }

    &__product-card {
      width: 210px;
      margin: auto;
    }

    &__start-a-return-section {
      padding: var(--spacing-400);
      margin-top: var(--spacing-500);
      border-left: none;
      border-top: solid 1px $color-border-primary-default;
    }
  }
}
</style>
